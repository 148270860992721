/**
 * ドロワーメニュー
 * <details>タグと<summary>タグを組み合わせて作成
 */
export default function initDrawer() {
  const drawer = document.getElementById("js-site-drawer");
  const openTrigger = document.getElementById("js-site-drawer-open");
  const closeTriggers = document.querySelectorAll(".js-site-drawer-close");

  // details要素の開閉時にaria-expandedを更新
  drawer.addEventListener("toggle", () => {
    const expanded = drawer.hasAttribute("open");
    openTrigger.setAttribute("aria-expanded", expanded);
  });
}
