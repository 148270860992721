export default function initHeader() {
  setHeaderHeight();

  scrollHeaderFixed();
}

/**
 * ヘッダーの高さをCSS変数に設定する関数
 * 要素のリサイズを検知して再取得します。
 */
function setHeaderHeight(cssVariableName = "--header-height-gutter") {
  const header = document.querySelector(".js-header-fixed");
  if (!header) return;

  let headerH = header.clientHeight;
  document.documentElement.style.setProperty(cssVariableName, headerH + "px");

  // 要素のリサイズを監視して再取得
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      // ヘッダーの高さを再設定
      headerH = entry.target.clientHeight;
      document.documentElement.style.setProperty(
        cssVariableName,
        headerH + "px"
      );
    }
  });
  resizeObserver.observe(header);
}

/**
 * scrollでheader固定
 */
function scrollHeaderFixed() {
  const fixedHeader = document.querySelector(".js-header-fixed");

  if (!fixedHeader) return;

  function scrollHandler() {
    // 一定量スクロールしたら表示
    fixedHeader.classList.toggle("is-header-fixed", window.scrollY > 100);
  }

  window.addEventListener("scroll", scrollHandler);
  scrollHandler();
}



//***フォントサイズ
var html = document.getElementsByTagName('html')[0];
var fsS = document.getElementById('js-font-small');
var fsM = document.getElementById('js-font-medium');
var fsL = document.getElementById('js-font-large');
var fontS = '54.6875%'; //14px
var fontM = '62.5%'; //16px
var fontL = '74.21875%'; //19px
var expireDay = 7; //7日間

// クッキーを取得する関数
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// クッキーを設定する関数
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// クッキーが設定されていない場合
if (!getCookie('cookieFontSize')) {
  // クッキー未登録時
  html.style.fontSize = fontM;
  fsM.classList.add('is-active');
} else if (getCookie('cookieFontSize') == 'font_small') {
  html.style.fontSize = fontS;
  fsL.classList.remove('is-active');
  fsM.classList.remove('is-active');
  fsS.classList.add('is-active');
} else if (getCookie('cookieFontSize') == 'font_medium') {
  html.style.fontSize = fontM;
  fsS.classList.remove('is-active');
  fsL.classList.remove('is-active');
  fsM.classList.add('is-active');
} else if (getCookie('cookieFontSize') == 'font_large') {
  html.style.fontSize = fontL;
  fsS.classList.remove('is-active');
  fsM.classList.remove('is-active');
  fsL.classList.add('is-active');
}

// フォントサイズ小イベント
fsS.addEventListener('click', function(e) {
  e.preventDefault();
  fsM.classList.remove('is-active');
  fsL.classList.remove('is-active');
  fsS.classList.add('is-active');
  html.style.fontSize = fontS;
  setCookie('cookieFontSize', 'font_small', expireDay);
});

// フォントサイズ中イベント
fsM.addEventListener('click', function(e) {
  e.preventDefault();
  fsS.classList.remove('is-active');
  fsL.classList.remove('is-active');
  fsM.classList.add('is-active');
  html.style.fontSize = fontM;
  setCookie('cookieFontSize', 'font_medium', expireDay);
});

// フォントサイズ大イベント
fsL.addEventListener('click', function(e) {
  e.preventDefault();
  fsS.classList.remove('is-active');
  fsM.classList.remove('is-active');
  fsL.classList.add('is-active');
  html.style.fontSize = fontL;
  setCookie('cookieFontSize', 'font_large', expireDay);
});
