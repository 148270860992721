/**
 * IntersectionObserverのデフォルトオプション。
 * @typedef {Object} Options
 * @property {Element|null} root - 視野の基準となる要素。nullの場合はブラウザのビューポート。
 * @property {string} rootMargin - 基準要素周囲の余白（CSSのmarginと同様の値）。
 * @property {number} threshold - 交差が発生するしきい値（0であれば1ピクセルでも交差時に実行）。
 */
const defaultOptions = {
  root: null,
  rootMargin: "-40px",
  threshold: 0,
};

/**
 * スクロールに応じた汎用的なアニメーション処理
 *
 * @param {NodeListOf<Element>} targets - 監視対象の要素。指定がない場合、.js-scroll-animationクラスを含む要素が対象となる。
 * @param {Options} [options={}] - IntersectionObserverのオプション設定。
 *
 * @example
 * // デフォルトのターゲット (.js-scroll-animation) を使用し、optionsのみをカスタマイズする場合
 * initObserveAnimation(undefined, {
 *   threshold: 0.5,       // しきい値を50%に設定
 *   rootMargin: "10px",   // 視野のマージンを10pxに設定
 * });
 */
export default function initObserveAnimation(
  targets = document.querySelectorAll("[class*='js-scroll-animation']"),
  options
  
) {
  if (!targets) return;
  const targetsLength = targets.length;

  const mergedOptions = { ...defaultOptions, ...options };
  const observer = new IntersectionObserver(handleObserve, mergedOptions);

  targets.forEach((target) => observer.observe(target));
}

/**
 * IntersectionObserverのコールバック関数。
 * 要素がビューポートに入った時にアニメーションクラスを追加し、監視を停止します。
 *
 * @param {IntersectionObserverEntry[]} entries - 監視対象のエントリの配列。
 */
function handleObserve(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("is-animated");
      observer.unobserve(entry.target);
    }
  });
}
