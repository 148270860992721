/**
 * ハッシュリンククリック時smooth、 headerガター調整
 */
export default function initSmoothScroll() {
  const anchorLinks = document.querySelectorAll('a[href*="#"]');

  anchorLinks.forEach(_addClickEventListener);

  // アンカー付きでページ遷移した場合
  const urlHash = location.hash;
  if (urlHash) {
    const targetElm = document.querySelector(urlHash);

    window.addEventListener("load", () => {
      _scrollToTarget(targetElm);
    });
  }

  // MutationObserverを使って動的に追加されたリンクを監視する
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (
          node.nodeType === Node.ELEMENT_NODE &&
          node.matches('a[href*="#"]')
        ) {
          _addClickEventListener(node);
        }

        // 子孫要素もチェック
        node.querySelectorAll?.('a[href*="#"]').forEach(_addClickEventListener);
      });
    });
  });

  // aタグにクリックイベントを追加する関数
  function _addClickEventListener(link) {
    link.addEventListener("click", (e) => {
      const targetElm = document.querySelector(link.hash);

      if (targetElm) {
        e.preventDefault();
        _scrollToTarget(targetElm);
      }
    });
  }

  // 引数のターゲットをもとに画面スクロールする関数
  function _scrollToTarget(targetElm, smooth = true) {
    if (targetElm) {
      const targetRect = targetElm.getBoundingClientRect().top;
      let fixGutter = document.querySelector(".js-header-fixed").clientHeight;

      const targetPos = targetRect + window.scrollY - fixGutter - 30;

      window.scrollTo({
        top: targetPos,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  }
}
