import initDrawer from "./initDrawer";
import initHeader from "./initHeader";
import initSmoothScroll from "./initSmoothScroll";
import initStickySidebar from "./initStickySidebar";
import initViewport from "./initViewport";
import initSplide from "./initSplide";
import initCommon from "./initCommon";
import initPopup from "./initPopup";
import ScrollHint from "scroll-hint"; // scroll-hintのインポート

export default function initCommonScript() {
  // viewport固定
  initViewport();

  // headerコンテンツ
  initHeader();

  // スムーススクロール
  initSmoothScroll();

  // ドロワーメニュー
  initDrawer();

  // 追従サイドバー
  initStickySidebar();

  // Splide
  initSplide();

  // 画像POPUP
  initPopup();

  // 共通パーツ
  initCommon();

  // scroll-hintの初期化
  document.addEventListener("DOMContentLoaded", () => {
    new ScrollHint(".js-scrollable"); // 適切なセレクタを指定してください
  });
}
