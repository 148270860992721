/**
 * ダイアログの開閉
 */
export default function initCommon() {

  function initDialog() {
    // すべてのダイアログオープンボタンを取得
    document.querySelectorAll(".p-dialog-open-button").forEach((openButton) => {
      openButton.addEventListener("click", (event) => {
        const dialogId = event.currentTarget.getAttribute("data-dialog-id");
        const dialog = document.querySelector(`.p-dialog[data-dialog-id="${dialogId}"]`);
        if (dialog) {
          dialog.showModal();
        }
      });
    });
  
    // すべてのダイアログクローズボタンを取得
    document.querySelectorAll(".p-dialog-close-button").forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        const dialog = closeButton.closest(".p-dialog");
        if (dialog) {
          dialog.close();
        }
      });
    });
  
    // ダイアログ自体をクリックした際に閉じる処理
    document.querySelectorAll(".p-dialog").forEach((dialog) => {
      dialog.addEventListener("click", (event) => {
        // クリックされたのがダイアログ本体（背景部分）の場合のみ閉じる
        if (event.target === dialog) {
          dialog.close();
        }
      });
    });
  }
  
  initDialog();
  
}