import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function initStickySidebar() {
  // カレントナビ作成
  createSectionNav();

  // サイドバー固定
  pinSidebar();
}

/**
 * sectionのidを自動取得してセクションナビを動的生成（[id^=sec-]のものに限る）
 */
function createSectionNav() {
  const nav = document.getElementById("js-section-tracker-nav");
  const sectionList = document.querySelectorAll("[id^=sec-]");
  if (!nav) return;

  // ナビゲーションのHTML文字列を格納して生成する
  let htmlTxt = "";
  htmlTxt += '<ul class="p-section-tracker-nav__list">';

  // sectionごとに処理をし、見出しを元にナビゲーションのHTMLを作成する
  sectionList.forEach((section) => {
    const h2 = section.querySelector("h2.wp-block-heading");
    const h3List = Array.from(section.querySelectorAll("h3.wp-block-heading"));
    if (!h2) return;

    // 自動で見出しのidを作成
    const h2Id = `nav-${section.id}`;
    h2.id = h2Id;

    htmlTxt += `<li class="p-section-tracker-nav__item" data-tracker="${section.id}">
                  <a class="p-section-tracker-nav__link" href="#${h2Id}">${h2.textContent}</a>`;

    // 子見出しがあれば作成
    if (h3List.length) {
      htmlTxt += `<ul class="p-section-tracker-nav__child-list">`;

      h3List.forEach((h3, i) => {
        // 自動で見出しのidを作成
        const h3Id = `nav-${section.id}-${i}`;
        h3.id = h3Id;

        htmlTxt += `<li class="p-section-tracker-nav__child-item">
                      <a class="p-section-tracker-nav__child-link" href="#${h3Id}">${h3.textContent}</a>
                    </li>`;
      });
      htmlTxt += "</ul>";
    }
    htmlTxt += "</li>";
  });
  htmlTxt += "</ul>";

  // 完成したHTMLで置き換え
  nav.innerHTML = htmlTxt;

  // ScrollTriggerによるカレントの付け替え処理を登録
  sectionList.forEach((section) => {
    ScrollTrigger.create({
      trigger: section,
      start: "top 25%",
      end: "bottom 25%",
      toggleClass: {
        targets: `[data-tracker="${section.id}"]`,
        className: "is-current",
      },
    });
  });
}

/**
 * サイドバーをsticky表示
 */
function pinSidebar() {
  // メディアクエリを作成
  let mm = gsap.matchMedia();

  mm.add("not all and (max-width: 1024px)", () => {
    // ヘッダーガターを取得（追従時に被らないように）
    let headerGutter = getComputedStyle(document.documentElement)
      .getPropertyValue("--header-height-gutter")
      .replace("px", "");
    headerGutter = parseInt(headerGutter, 10);

    gsap.to(".js-sticky-sidebar", {
      scrollTrigger: {
        trigger: ".js-sticky-sidebar",
        start: `top-=${headerGutter + 30} top`,
        end: "bottom bottom",
        endTrigger: ".l-page-has-sidebar",
        pin: true,
        pinSpacing: false,
        // markers: true,
      },
    });
  });
}
