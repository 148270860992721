export default function initPopup() {

  document.addEventListener('DOMContentLoaded', function() {
    const links = document.querySelectorAll('a.js-image-popup'); // クラス名で絞り込み
  
    links.forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault();
  
        const imageUrl = this.getAttribute('href');
        const imageTitle = this.dataset.title || '';
  
        const popup = document.createElement('div');
        popup.classList.add('p-image-popup');
        popup.innerHTML = `
          <div class="p-image-popup-content">
            <img src="${imageUrl}" alt="${imageTitle}">
            ${imageTitle ? `<p class="p-image-title">${imageTitle}</p>` : ''}
            <span class="p-popup-close">&times;</span>
          </div>
        `;
  
        document.body.appendChild(popup);
  
        const closeButton = popup.querySelector('.p-popup-close');
        closeButton.addEventListener('click', () => {
          document.body.removeChild(popup);
        });
  
        // オプション：ポップアップ外側をクリックで閉じる
        popup.addEventListener('click', (event) => {
          if (event.target === popup) {
            document.body.removeChild(popup);
          }
        });

      });
    });
  });

}



