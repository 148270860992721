// import Splide JS
import Splide from "@splidejs/splide";

export default function initSplide() {
  // ----------------------------------------
  // トップメインビジュアル
  // ----------------------------------------

  function _initMvSplide() {
    const splideTargetmv = document.querySelectorAll(".hm-mv-slider");
    if (!splideTargetmv) return;

    splideTargetmv.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 2000, // スライダーの移動時間
        padding: "20%", // スライダーの左右の余白
        gap: 40, // スライド間の余白
        breakpoints: {
          1024: {
            padding: 0,
            arrows: false,
          },
        },
      }).mount();
    });
  }
  _initMvSplide();

  // ----------------------------------------
  // お出かけセクション
  // ----------------------------------------

  function _initOdekakeSplide() {
    const splideTargetOdekake = document.querySelectorAll(".hm-odekake-slider");
    if (!splideTargetOdekake) return;

    splideTargetOdekake.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 2000, // スライダーの移動時間
        padding: "20%", // スライダーの左右の余白
        gap: 40, // スライド間の余白
        breakpoints: {
          1024: {
            gap:15,
            arrows: false,
             padding: "10%"
          },
        },
      }).mount();
    });
  }
  _initOdekakeSplide();

  // ----------------------------------------
  // バス事業のスライダー
  // ----------------------------------------

  function _initBussinessSplide() {
    const splideTargetbussiness = document.querySelectorAll(".rc-bussiness-slider");
    if (!splideTargetbussiness) return;
  
    splideTargetbussiness.forEach((slider) => {
      const splideInstance = new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pagination: false, // ページネーションを非表示
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 800, // スライダーの移動時間
        perPage: 1,
        perMove: 1,
        arrows:false,
        fixedWidth: 410,
        gap: 22, // スライド間の余白
        breakpoints: {
          1024: {
            fixedWidth: false,
            gap:10,
            pagination:true
          },
        },
      }).mount();
  
      // プログレスバー要素を取得
      const bar = slider.querySelector(".rc-business-progress-bar");
  
      // スライドが切り替わるたびにプログレスバーを更新
      splideInstance.on("mounted move", function () {
        const end = splideInstance.Components.Controller.getEnd() + 1;
        const rate = Math.min((splideInstance.index + 1) / end, 1);
        bar.style.width = String(100 * rate) + "%";
      });
    });
  }
  
  _initBussinessSplide();

  // ----------------------------------------
  // トラストツアーセクション
  // ----------------------------------------

  function _initTrustSplide() {
    const splideTargettrust = document.querySelectorAll(".p-trust-slider");
    if (!splideTargettrust) return;

    splideTargettrust.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 2000, // スライダーの移動時間
        padding: "20%", // スライダーの左右の余白
        gap: 40, // スライド間の余白
        breakpoints: {
          1024: {
            gap:15,
            arrows: false,
             padding: "10%"
          },
        },
      }).mount();
    });
  }
  _initTrustSplide();

  // ----------------------------------------
  // SNSーセクション
  // ----------------------------------------

  function _initSnsSplide() {
    const splideTargetsns = document.querySelectorAll(".hm-sns-slider");
    if (!splideTargetsns) return;

    splideTargetsns.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 800, // スライダーの移動時間
        gap:15,
        arrows: false,
        padding: "10%",
        destroy: true, // スライダーを破棄
        breakpoints: {
          1024: {
            destroy: false, // スライダーを起動
          },
        },
      }).mount();
    });
  }
  _initSnsSplide();

  // ----------------------------------------
  // 採用メッセージSPセクション
  // ----------------------------------------

  function _initMessageSplide() {
    const splideTargetmessage = document.querySelectorAll(".rc-message-sp-slider");
    if (!splideTargetmessage) return;

    splideTargetmessage.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 800, // スライダーの移動時間
        gap:15,
        padding: "30%",
        destroy: true, // スライダーを破棄
        arrows:false,
        pagination:false,
        breakpoints: {
          1024: {
            destroy: false, // スライダーを起動
          },
        },
      }).mount();
    });
  }
  _initMessageSplide();


    // ----------------------------------------
  // トラストツアーセクション
  // ----------------------------------------

  function _initOsusumeSplide() {
    const splideTargetosusume = document.querySelectorAll(".p-osusume-slider");
    if (!splideTargetosusume) return;

    splideTargetosusume.forEach((slider) => {
      new Splide(slider, {
        autoplay: true, // 自動再生
        type: "loop", // ループ
        pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
        interval: 5000, // 自動再生の間隔
        speed: 500, // スライダーの移動時間
        gap: 40, // スライド間の余白
        perPage:3,
        perMove:1,
        breakpoints: {
          1024: {
            gap:15,
            arrows: false,
             padding: "10%",
             perPage:1,
          },
        },
      }).mount();
    });
  }
  _initOsusumeSplide();

}
