// ============================================
// モジュール読み込み
// ============================================
import initCommonScript from "./libs/common";
import initObserveAnimation from "./libs/animation/initObserveAnimation";

// 共通script
initCommonScript();

// スクロール連動アニメーション
initObserveAnimation();
