/**
 * 375px以下はviewport固定（375px以下のレスポンシブ対応不要）
 */
export default function initViewport() {
  function switchViewport() {
    const minWidth = 375;
    const value =
      window.outerWidth > minWidth
        ? "width=device-width,initial-scale=1"
        : `width=${minWidth}`;
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport && viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  }

  window.addEventListener("resize", switchViewport, false);
  switchViewport();
}
